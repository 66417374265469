import { FilterCondition, FilterType } from '@/module/graphql'
import { FilterConditionValidator } from '@/module/components/lolth-filter/types'
import _ from 'lodash'
import { isNumeric, isNumericArray } from '@/module/common/util/common-util'

export function isValid(this: FilterCondition): boolean {
  // filterType是一定要的
  if (!this.filterType) return false

  switch (this.filterType) {
    case FilterType.And:
    case FilterType.Or:
      return !_.isEmpty(this.conditions)

    case FilterType.ById:
      return isNumeric(this.value)

    case FilterType.InIds:
      return this.value === '' || (this.value && isNumericArray(this.value))

    case FilterType.IsNull:
    case FilterType.IsNotNull:
      return !!this.field

    case FilterType.String:
    case FilterType.Date:
    case FilterType.Bool:
    case FilterType.Array:
    case FilterType.VEnum:
      return !!this.field && !_.isEmpty(this.value)

    case FilterType.Number:
      return !!this.field && isNumericArray(this.value)

    case FilterType.Custom:
      return !!this.operator
    default: return true
  }
}

export function isValid_(that: FilterCondition & FilterConditionValidator) {
  if (that && !that.isValid) {
    that.isValid = isValid
  }
  return that && that.isValid()
}
