import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry } from '@/module/components/lolth-explorer/supports'
import CmpEmployeeShiftCellRenderer from '@/module/plugin-attendance/views/cmp-employee-shift-cell-renderer.vue'
import CmpAtdEmployeeShiftFilter from '@/module/plugin-attendance/views/filter/cmp-atd-employee-shift-filter.vue'
import CmpAtdEmployeeShiftFloatingFilter
  from '@/module/plugin-attendance/views/filter/cmp-atd-employee-shift-floating-filter.vue'

export const REALM_CODE_ATD = 'Attendance'

export class AtdModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>) {
    // FrameworkComponentsRegistry.employeeShiftCellRenderer = CmpEmployeeShiftCellRenderer
    FrameworkComponentsRegistry.employeeShiftFilter = CmpAtdEmployeeShiftFilter
    FrameworkComponentsRegistry.employeeShiftFloatingFilter = CmpAtdEmployeeShiftFloatingFilter
  }
}

export default new AtdModuleHook()
