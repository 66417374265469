












import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'
import { AtdShift, FilterCondition, FilterType, MdDictionaryEntry } from '@/module/graphql'
import _ from 'lodash'
import { FilterConditionView } from '@/module/components/lolth-filter/types'

type IAtdEmployeeShiftFloatingFilterParams = IFloatingFilterParams & {
  defaultShift?: AtdShift
}

@Component
export default class CmpAtdEmployeeShiftFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IAtdEmployeeShiftFloatingFilterParams | null = null
  private mValue: AtdShift = null

  private mEmptyShift = [{
    id: '-1',
    name: '未排班'
  } as AtdShift]

  created() {
    if (this.params.defaultShift) {
      this.mValue = this.params.defaultShift
      this.onValueChanged()
    }
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(parentModel: FilterConditionView, filterChangedEvent?: FilterChangedEvent): void {
    this.mValue = parentModel.filterInput as AtdShift
  }

  onValueChanged() {
    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged(this.mValue)
    })
  }
}
