











import { Component, Vue } from 'vue-property-decorator'
import { AtdSchedule, AtdShift, FilterType, RowRequest } from '@/module/graphql'
import gql from 'graphql-tag'
import { IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community/dist/lib/interfaces/iFilter'
import { FilterConditionView } from '@/module/components/lolth-filter/types'
import _ from 'lodash'

declare interface IAtdEmployeeShiftFilterParams extends IFilterParams {
  scheduledAt: Date
  defaultShift?: AtdShift
}

@Component
export default class CmpAtdEmployeeShiftFilter extends Vue implements IFilterComp {
  private params: IAtdEmployeeShiftFilterParams | null = null

  private mSelectedShift: AtdShift = null
  private mFilterCondition: FilterConditionView = {
    filterType: FilterType.InIds,
    value: null
  }

  private mEmptyShift = [{
    id: '-1',
    name: '未排班'
  } as AtdShift]

  get isValid() {
    return !!this.mSelectedShift
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true
  }

  isFilterActive(): boolean {
    return this.isValid
  }

  getFrameworkComponentInstance(): any {
    return this
  }

  getModel(): any {
    return this.mFilterCondition
  }

  setModel(model: any): void {
    // this.clearContent()
  }

  buildFilterCondition() {
    if (!this.mSelectedShift) {
      this.mFilterCondition.not = false
      this.mFilterCondition.value = null
      this.mFilterCondition.filterInput = null
      this.params?.filterChangedCallback()
    } else if (_.toNumber(this.mSelectedShift.id) !== -1) {
      this.$apollo.query({
        query: gql`query ($rowRequest: RowRequest!) {
          AtdSchedules(rowRequest: $rowRequest) {
            rows {
              employee { id }
            }
          }
        }`,
        variables: {
          rowRequest: {
            filter: {
              filterType: FilterType.And,
              conditions: [{
                filterType: FilterType.Number,
                field: 'shift.id',
                operator: 'equals',
                value: this.mSelectedShift.id
              }, {
                filterType: FilterType.Date,
                field: 'scheduledAt',
                operator: 'betweenDate',
                value: this.$formatDate(this.params.scheduledAt) + ',' + this.$formatDate(this.params.scheduledAt)
              }]
            }
          } as RowRequest
        }
      }).then(resp => {
        this.mFilterCondition.not = false
        this.mFilterCondition.value =
          _.uniq(resp.data.AtdSchedules.rows.map((schedule: AtdSchedule) => schedule.employee.id)).join(',')
        this.mFilterCondition.filterInput = this.mSelectedShift
        this.params?.filterChangedCallback()
      })
    } else {
      this.$apollo.query({
        query: gql`query ($rowRequest: RowRequest!) {
          AtdSchedules(rowRequest: $rowRequest) {
            rows {
              employee { id }
            }
          }
        }`,
        variables: {
          rowRequest: {
            filter: {
              filterType: FilterType.Date,
              field: 'scheduledAt',
              operator: 'betweenDate',
              value: this.$formatDate(this.params.scheduledAt) + ',' + this.$formatDate(this.params.scheduledAt)
            }
          } as RowRequest
        }
      }).then(resp => {
        this.mFilterCondition.not = true
        this.mFilterCondition.value =
          _.uniq(resp.data.AtdSchedules.rows.map((schedule: AtdSchedule) => schedule.employee.id)).join(',')
        this.mFilterCondition.filterInput = this.mSelectedShift
        this.params?.filterChangedCallback()
      })
    }
  }

  onValueChanged() {
    this.buildFilterCondition()
  }

  // noinspection JSUnusedGlobalSymbols
  onFloatingFilterChanged(shift: AtdShift) {
    this.mSelectedShift = shift
    this.buildFilterCondition()
  }

  clearContent() {
    this.mSelectedShift = null
    this.buildFilterCondition()
  }
}
