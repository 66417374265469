import { FilterCondition, FilterType } from '@/module/graphql'
import { Indexed } from '@/d2admin/delegate'

export declare type FilterViewDef = {
  fieldKey?: string
  fieldName: string
  filterViewType?: FilterViewType
  enumGroup?: string
  explorerTypeKey?: string
  customFilter?: string
  customProps?: Indexed
  class?: string
  disabledOperators?: string[]
}

export declare interface FilterConditionValidator {
  isValid?: (this: FilterCondition) => boolean
}

export declare type FilterConditionView = FilterCondition & {
  filterViewDef?: FilterViewDef,
  filterInput?: any
} & FilterConditionValidator

export enum FilterViewType {
  StringFilter = 'stringFilter',
  NumberFilter = 'numberFilter',
  DateFilter = 'dateFilter',
  BooleanFilter = 'booleanFilter',
  TagsFilter = 'tagsFilter',
  EnumFilter = 'enumFilter',
  ReadonlyFilter = 'readonlyFilter',
  StringArrayFilter = 'stringArrayFilter',
  CustomFilter = 'customFilter'
}

export const EMPTY_FILTER = Object.freeze({
  filterType: FilterType.And,
  conditions: []
})
