import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType, RowRequest, SortColumn } from '@/module/graphql'
import gql from 'graphql-tag'
import LocalDbDao from '@/module/common/local-db-dao'
import LolthInputSelect from '@/module/components/lolth-input-select.vue'
import _ from 'lodash'
import { getRowId } from '@/module/components/lolth-explorer/supports'
import { stripFilterViewDef } from '@/module/butler/butler-util'

@Component
export default class LolthTypedInputSelect<T> extends LolthInputSelect {
  @Prop({ required: true }) readonly explorerTypeKey!: string
  @Prop() readonly fixedFilterCondition?: FilterCondition
  @Prop() readonly sortBy?: SortColumn[]

  @Prop() readonly fetchPresetValue?: boolean
  @Prop() readonly customOptionsFetcher?: () => T[] | Promise<T[]>
  @Prop() readonly specialOptions?: T[]
  @Prop() readonly bindPath?: string

  get explorerType() {
    return LocalDbDao.getExplorerType(this.explorerTypeKey)
  }

  @Watch('fixedFilterCondition')
  public onFixedFilterConditionChanged() {
    this.resetOptionsLoaded()
  }

  protected getValueKey() {
    return this.explorerType.field('id') ? 'id'
      : this.explorerType.field('code') ? 'code'
        : this.explorerType.field('key') ? 'key' : 'dataId'
  }

  protected formatItemLabel(item:any) {
    return this.itemLabelFormatter
      ? this.itemLabelFormatter(item)
      : this.explorerType.formatLabel(item)
  }

  protected needFetchPresetValue(): boolean {
    return !_.isNil(this.fetchPresetValue) && this.fetchPresetValue
  }

  protected doFetchPresetValue(items: any[]): any {
    if (this.presetValueFetcher) return this.presetValueFetcher(items)

    const fields: string = this.explorerType.graphQLFetchFields()
    const rowRequest: RowRequest = {
      filter: {
        filterType: FilterType.InIds,
        value: items.map(item => { return item.id || item.code }).join(',')
      }
    }

    return this.$apollo.query({
      query: gql`
        query SelectFetchOne($rowRequest: RowRequest!) {
          ${this.explorerType.queryType}(rowRequest: $rowRequest) {
            rows { ${fields} }
          }
        }`,
      variables: {
        rowRequest
      }
    }).then(data => {
      return _.sortBy(data.data[this.explorerType.queryType].rows, row => {
        const index = _.findIndex([...(items || [])], (item: any) => {
          return getRowId(row) === getRowId(item)
        })
        return index >= 0 ? index : Number.MAX_VALUE
      })
    })
  }

  protected doFetchOptions(): any {
    if (this.customOptionsFetcher) return this.customOptionsFetcher()

    const fields: string = this.explorerType.graphQLFetchFields()
    const rowRequest: RowRequest = {
      filter: stripFilterViewDef(this.fixedFilterCondition)
    }
    let sortBy = this.sortBy
    if (!sortBy && this.explorerType.defaultSort) {
      sortBy = [this.explorerType.defaultSort]
    }
    if (sortBy) {
      rowRequest.sort = sortBy
    }

    return this.$apollo.query({
      query: gql`query SelectFetchData($rowRequest: RowRequest!) {
        ${this.explorerType.queryType}(rowRequest: $rowRequest) {
          rows { ${fields} }
        }
      }`,
      variables: {
        rowRequest
      }
    }).then(data => {
      if (this.bindPath) {
        return data.data[this.explorerType.queryType].rows
          .map((item: any) => _.get(item, this.bindPath!))
      } else {
        const result = data.data[this.explorerType.queryType].rows || []
        if (this.specialOptions) {
          result.splice(0, 0, ...this.specialOptions)
        }
        return result
      }
    })
  }
}
