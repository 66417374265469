

















































import { Component } from 'vue-property-decorator'
import BaseInput from './base-input'
import copy from 'copy-to-clipboard'
import LocalDbDao from '@/module/common/local-db-dao'
import { CascaderNode } from '@/module/components/types'
import { Maybe } from 'graphql/jsutils/Maybe'
import { FilterCondition, FilterType, MdDictionaryEntry, SortColumn, SortType } from '@/module/graphql'
import { isValid_ } from '@/module/common/filter-condition-extend'
import gql from 'graphql-tag'
import LolthTypedInputSelect from '@/module/components/typed/lolth-typed-input-select'

@Component
export default class CmpMdTableDataInputDict extends BaseInput<number> {
  private mGroup: CascaderNode[] = null
  private mChooserVisible = false

  private mDictEntry: MdDictionaryEntry = null

  get syncedDictEntry(): Maybe<MdDictionaryEntry> {
    return this.mDictEntry
  }

  set syncedDictEntry(val: Maybe<MdDictionaryEntry>) {
    this.mDictEntry = val
    this.syncValue = !val ? null : parseInt(val.id)
  }

  get filterCondition(): FilterCondition {
    if (!this.column.option?.dictId) {
      throw new Error('无效的选项表字段, 未指定选项表')
    }

    const filterCondition = {
      filterType: FilterType.Number,
      field: 'dictionary.id',
      operator: 'equals',
      value: this.column.option.dictId
    }

    return isValid_(this.column.option?.dictFilter)
      ? {
        filterType: FilterType.And,
        conditions: [
          filterCondition,
          this.column.option.dictFilter
        ]
      } : filterCondition
  }

  get sortByColumn(): SortColumn[] {
    return [{
      field: 'seq',
      sort: SortType.Asc
    }]
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdDictionaryEntry')
  }

  created() {
    if (this.syncValue) {
      this.$apollo.query({
        query: gql`query {
          MdDictionaryEntry(id: ${this.syncValue}) {
            id key value valueForShort seq
          }
        }`
      }).then(data => {
        this.mDictEntry = data.data.MdDictionaryEntry
      })
    }
  }

  mounted() {
    if (!this.column.option.chooser) {
      this.$apollo.query({
        query: gql`query {
          MdDictionary(id: ${this.column.option.dictId}) {
            groups
          }
        }`
      }).then(data => {
        this.mGroup = data.data.MdDictionary.groups || null
      })
    }
  }

  copyToClipboard() {
    if (!this.syncedDictEntry) return
    copy(this.explorerType.formatLabel(this.syncedDictEntry))
  }

  onChooserSelect(dictEntries: MdDictionaryEntry[]) {
    if (!dictEntries || dictEntries.length <= 0) return
    this.syncedDictEntry = dictEntries[0]
  }

  resetSelectOptions() {
    const vSelect = this.$refs.vSelect as LolthTypedInputSelect<MdDictionaryEntry>
    vSelect?.resetOptionsLoaded()
  }
}
