import { ExplorerType } from '@/module/components/lolth-explorer/explorer-type'
import LocalDbDao from '@/module/common/local-db-dao'
import { CellClassParams, ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { ColGroupDef } from 'ag-grid-community'
import _ from 'lodash'
import { SortColumn, SortType } from '@/module/graphql'
import {
  ExplorerScalarType,
  getDirective,
  isIgnoreColumnField,
  isIgnoreFetchField
} from '@/module/components/lolth-explorer/supports'
import { FilterViewDef, FilterViewType } from '@/module/components/lolth-filter/types'
import { Indexed } from '@/d2admin/delegate'
import { DATE_FORMAT, DATE_TIME_FORMAT, NO_PERMISSION_NUMBER } from '@/module/common/constants'
import json5 from 'json5'
import moment from 'moment/moment'

const HIDDEN_FIELDS = ['id', 'uid', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'ver']

interface BuildAgGridColumnDefOptions {
  fieldPath?: string,
  groupHeader?: boolean,
  fieldNamePrefix?: string,
  customRemapField?: string,
  customRemapColumnType?: string,
  expendingParentField?: ExplorerTypeField
}

interface ExplorerTypeFieldClassClass {
  valueClasses?: { [value : string]: string }
  simpleMather?: { [matcher : string]: string } // hasContent |positive
  customMatcher?: (value: any) => string
}

export class ExplorerTypeField {
  key: string
  name: string
  typeKey: string
  notNull?: boolean
  isList?: boolean
  parentTypeKey: string

  unique?: string[]
  valuedEnumGroup?: string
  nestedFields?: string[]
  concatFieldName?: boolean

  expandField?: string
  expandType?: string
  associatingField?: string
  dominatorField?: string
  expandIgnoreFields?: string[]
  defaultSort?: SortColumn

  ignoreColumn?: boolean // ignore creating ag-grid column for this field
  ignoreFetch?: boolean // ignore fetch field value from Server via GraphQL
  hiddenByDefault?: boolean // set column hide by default

  customCellRenderer?: string
  customCellRendererProps?: Indexed
  customFilter?: string
  customFloatingFilter?: string
  customFilterProps?: Indexed
  customRemapField?: string
  customRemapColumnType?: string
  customClass?: ExplorerTypeFieldClassClass
  disableQuery?: boolean
  dateFormat?: string

  fetchDeep?: boolean // fetch each field recursively
  fetchLazy?: boolean // fetch field in new requests after major data is fetched
  fetchLazyBatch?: boolean // fetch field in a new request in batch after major data is fetched

  constructor(gqlField: any, parentTypeKey: string) {
    this.key = gqlField.name
    switch (this.key) {
      case 'code':
        this.name = gqlField.description || '编号'
        break
      case 'createdBy':
        this.name = gqlField.description || '创建者'
        break
      case 'createdAt':
        this.name = gqlField.description || '创建时间'
        break
      case 'updatedBy':
        this.name = gqlField.description || '最近修改者'
        break
      case 'updatedAt':
        this.name = gqlField.description || '最近修改时间'
        break
      default: {
        this.name = _.isNil(gqlField.description) ? gqlField.name : gqlField.description
      }
    }
    this.parentTypeKey = parentTypeKey

    // check if is not-null
    if (gqlField.type.kind === 'NON_NULL') this.notNull = true

    // check if is List
    if (gqlField.type.kind === 'LIST' ||
      gqlField.type.ofType?.kind === 'LIST') this.isList = true

    // check ignore
    this.ignoreColumn = isIgnoreColumnField(gqlField)
    this.ignoreFetch = isIgnoreFetchField(gqlField)

    // resolve type name
    if (gqlField.type.name) {
      this.typeKey = gqlField.type.name
    } else if (gqlField.type.ofType?.name) {
      this.typeKey = gqlField.type.ofType.name
    } else if (gqlField.type.ofType?.ofType?.name) {
      this.typeKey = gqlField.type.ofType.ofType.name
    } else if (gqlField.type.ofType?.ofType?.ofType?.name) {
      this.typeKey = gqlField.type.ofType.ofType.ofType.name
    } else if (gqlField.type.ofType?.ofType?.ofType?.ofType?.name) {
      this.typeKey = gqlField.type.ofType.ofType.ofType.ofType.name
    } else if (gqlField.type.ofType?.ofType?.ofType?.ofType?.ofType?.name) {
      this.typeKey = gqlField.type.ofType.ofType.ofType.ofType.ofType.name
    } else if (gqlField.type.ofType?.ofType?.ofType?.ofType?.ofType?.ofType?.name) {
      this.typeKey = gqlField.type.ofType.ofType.ofType.ofType.ofType.ofType.name
    } else {
      throw new Error('Unknown field type ' + gqlField.name)
    }

    let directive = getDirective('unique', gqlField.directives)
    if (directive?.unique) this.unique = directive.unique

    directive = getDirective('valuedEnum', gqlField.directives)
    if (directive?.name) this.valuedEnumGroup = directive.name

    directive = getDirective('explorerToOne', gqlField.directives)
    if (directive?.nestedFields) this.nestedFields = directive.nestedFields
    if (directive?.concatFieldName) this.concatFieldName = directive.concatFieldName

    directive = getDirective('explorerToMany', gqlField.directives)
    if (directive?.expandField) this.expandField = directive.expandField
    if (directive?.expandIgnoreFields &&
        directive?.expandIgnoreFields.length > 0) this.expandIgnoreFields = directive.expandIgnoreFields
    if (directive?.defaultSortBy) {
      this.defaultSort = {
        field: directive?.defaultSortBy,
        sort: directive?.defaultSortOrder || SortType.Asc
      }
    }

    directive = getDirective('explorerToManyPager', gqlField.directives)
    if (directive?.expandType) this.expandType = directive.expandType
    if (directive?.associatingField) this.associatingField = directive.associatingField
    if (directive?.dominatorField) this.dominatorField = directive.dominatorField
    if (directive?.expandIgnoreFields &&
      directive?.expandIgnoreFields.length > 0) this.expandIgnoreFields = directive.expandIgnoreFields
    if (directive?.defaultSortBy) {
      this.defaultSort = {
        field: directive?.defaultSortBy,
        sort: directive?.defaultSortOrder || SortType.Asc
      }
    }

    directive = getDirective('explorerIgnore', gqlField.directives)
    if (directive) {
      this.hiddenByDefault = !(directive.display || false)
      if (directive.column) this.ignoreFetch = false
    }

    directive = getDirective('explorerCustomCellRenderer', gqlField.directives)
    if (directive?.renderer) {
      this.customCellRenderer = directive.renderer
      this.customCellRendererProps = json5.parse(directive.customProps || null)
      this.ignoreColumn = false
      this.ignoreFetch = false
      // this.hiddenByDefault = false
    }

    directive = getDirective('explorerCustomFilter', gqlField.directives)
    if (directive && directive.filter) this.customFilter = directive.filter
    if (directive && directive.floatingFilter) this.customFloatingFilter = directive.floatingFilter
    if (directive && directive.customProps) this.customFilterProps = json5.parse(directive.customProps)

    directive = getDirective('explorerCustomClass', gqlField.directives)
    if (directive) {
      this.customClass = {
        valueClasses: json5.parse(directive.valueClasses || null),
        simpleMather: json5.parse(directive.simpleMather || null)
      }
    }

    directive = getDirective('explorerRemapFilter', gqlField.directives)
    if (directive && directive.mapField) this.customRemapField = directive.mapField
    if (directive && directive.columnType) this.customRemapColumnType = directive.columnType

    directive = getDirective('explorerDisableQuery', gqlField.directives)
    if (directive) this.disableQuery = true

    directive = getDirective('explorerFetchPolicy', gqlField.directives)
    if (directive && directive.deep) this.fetchDeep = directive.deep
    if (directive && directive.lazy) this.fetchLazy = directive.lazy
    if (directive && directive.lazyBatch) this.fetchLazyBatch = directive.lazyBatch

    directive = getDirective('explorerDateFormat', gqlField.directives)
    if (directive && directive.format) this.dateFormat = directive.format
  }

  isScalar(): boolean {
    return this.typeKey === 'ID' ||
      this.typeKey === 'String' ||
      this.typeKey === 'Boolean' ||
      this.typeKey === 'Int' ||
      this.typeKey === 'Float' ||
      this.typeKey === 'Date' ||
      this.typeKey === 'DateTime' ||
      this.typeKey === 'Time' ||
      this.typeKey === 'ValuedEnum' ||
      this.typeKey === 'Json' ||
      this.typeKey === 'Map' ||
      this.typeKey === 'File'
  }

  isAuditField(): boolean {
    return HIDDEN_FIELDS.indexOf(this.key) >= 0
  }

  type(types: { [key: string]: ExplorerType }): ExplorerType {
    return types[this.typeKey]
  }

  get explorerType(): ExplorerType {
    return LocalDbDao.getExplorerType(this.typeKey)
  }

  protected agGridColumnType(): string {
    if (this.disableQuery) return 'rawColumn'
    if (this.key === 'tags' && this.isList) return 'tagsColumn'
    switch (this.typeKey) {
      case ExplorerScalarType.ID:
      case ExplorerScalarType.String:
      case ExplorerScalarType.Time:
        return 'stringColumn'
      case ExplorerScalarType.Boolean:
        return 'booleanColumn'
      case ExplorerScalarType.Int:
      case ExplorerScalarType.Float:
        return 'numberColumn'
      case ExplorerScalarType.Date:
      case ExplorerScalarType.DateTime:
        return 'dateColumn'
      case ExplorerScalarType.ValuedEnum:
        return 'valuedEnumColumn'
      case ExplorerScalarType.Json:
        return 'rawColumn'
      case ExplorerScalarType.Map:
        return 'extraColumn'
      case ExplorerScalarType.File:
        return 'filesColumn'
    }
    throw new Error(`不支持展示的字段类型 ${this.name}(${this.typeKey})`)
  }

  protected agGridFilterViewDef(columnDef: ColDef): FilterViewDef {
    const viewDef:FilterViewDef = {
      fieldKey: columnDef.field || '',
      fieldName: columnDef.headerName || '',
      enumGroup: this.valuedEnumGroup
    }
    if (this.customFilter) {
      viewDef.filterViewType = FilterViewType.CustomFilter
      viewDef.customFilter = _.kebabCase(this.customFilter)
      viewDef.customProps = _.clone(this.customFilterProps)
    } else if (this.key === 'tags' && this.isList) {
      viewDef.filterViewType = FilterViewType.TagsFilter
      viewDef.explorerTypeKey = this.parentTypeKey
    } else {
      switch (this.typeKey) {
        case ExplorerScalarType.ID:
        case ExplorerScalarType.String:
          viewDef.filterViewType = this.isList
            ? FilterViewType.StringArrayFilter : FilterViewType.StringFilter; break
        case ExplorerScalarType.Boolean:
          viewDef.filterViewType = FilterViewType.BooleanFilter; break
        case ExplorerScalarType.Int:
        case ExplorerScalarType.Float:
          viewDef.filterViewType = FilterViewType.NumberFilter; break
        case ExplorerScalarType.Date:
        case ExplorerScalarType.DateTime:
          viewDef.filterViewType = FilterViewType.DateFilter; break
        case ExplorerScalarType.ValuedEnum:
          viewDef.filterViewType = FilterViewType.EnumFilter; break
        default:
          viewDef.filterViewType = FilterViewType.ReadonlyFilter; break
      }
    }
    return viewDef
  }

  // 使用上级级联下来的自定义查询设置, 并且只应用一次, 之后有相同的级联设置则关闭查询排序分组功能
  protected processRemapFilter(columnDef: ColDef, options: BuildAgGridColumnDefOptions, context: any = {}) {
    if (!context.processedRemapFilter) context.processedRemapFilter = {}
    if (options.customRemapField) {
      if (!context.processedRemapFilter[options.customRemapField]) {
        columnDef.filterParams.viewDef.fieldKey = options.customRemapField
        if (options.customRemapColumnType) {
          columnDef.type = options.customRemapColumnType
          columnDef.filterParams.viewDef.filterViewType = resolveFilterViewType(columnDef.type as string)
        }
        context.processedRemapFilter[options.customRemapField] = options.customRemapField
      } else {
        // 关闭其它级联字段的查询排序分组功能
        columnDef.type = 'rawColumn'
      }
    } else if (this.customRemapField) {
      columnDef.filterParams.viewDef.fieldKey = this.customRemapField
      if (this.customRemapColumnType) {
        columnDef.type = this.customRemapColumnType
        columnDef.filterParams.viewDef.filterViewType = resolveFilterViewType(columnDef.type as string)
      }
    }
  }

  // 一个字段的类型可能会有
  // Scalar: 根据类型指定columnDef类型, 指定customCellRenderer
  // Scalar-Json: 如果没有ignoreColumn掉, 或者指定customCellRenderer, 报错
  // List: 如果没有ignoreColumn掉, 或者指定customCellRenderer, 报错
  // Type:
  //   - 如果指定了nestedFields, 递归进去级联创建columnDef
  //   - 如果没有ignoreColumn掉, 或者指定customCellRenderer, 报错

  buildAgGridColumnDef(options: BuildAgGridColumnDefOptions, context: any = {}): ColDef[] | ColGroupDef[] {
    if (this.ignoreColumn) {
      throw new Error(`不支持的字段类型 ${this.name}(${this.typeKey})`)
    }
    // Json一般都是用自定义cellRenderer的, 所以留到下面customCellRenderer那边再处理
    if (this.isScalar() && this.typeKey !== ExplorerScalarType.Json) {
      // scalar field
      const fieldPath = options.fieldPath || ''
      const columnDef: ColDef = {
        headerName: (options.fieldNamePrefix || '') + this.name,
        colId: fieldPath + (fieldPath ? '.' : '') + this.key,
        field: fieldPath + (fieldPath ? '.' : '') + this.key,
        type: this.agGridColumnType(),
        hide: this.isAuditField() || this.hiddenByDefault,
        enableRowGroup: !this.disableQuery
      }
      if (this.disableQuery) {
        // disable aggregation value for disableQuery field
        columnDef.enableValue = false
      }
      // set filter, including custom filter
      columnDef.filterParams = {
        viewDef: this.agGridFilterViewDef(columnDef)
      }
      // set customCellRenderer
      if (this.customCellRenderer) {
        columnDef.cellRenderer = this.customCellRenderer
        columnDef.cellRendererParams = Object.assign({
          explorerTypeKey: this.parentTypeKey,
          enumGroup: this.valuedEnumGroup
        }, this.customCellRendererProps)
        columnDef.autoHeight = true
      } else if (this.typeKey === ExplorerScalarType.Date ||
          this.typeKey === ExplorerScalarType.DateTime) {
        columnDef.cellRenderer = 'dateRenderer'
        columnDef.cellRendererParams = Object.assign({
          format: this.dateFormat ||
            this.typeKey === ExplorerScalarType.Date ? DATE_FORMAT : DATE_TIME_FORMAT
        }, this.customCellRendererProps)
      }

      if (this.customFilter) {
        columnDef.filter = 'explorerFilter'
        columnDef.sortable = false
        columnDef.enableRowGroup = false
      }
      this.processRemapFilter(columnDef, options, context)
      if (this.customFloatingFilter) columnDef.floatingFilterComponent = this.customFloatingFilter

      if (this.customClass) {
        columnDef.cellClass = (param: CellClassParams) => {
          const value = _.get(param.data, columnDef.field || '')
          let clazz = this.customClass.valueClasses && this.customClass.valueClasses[value]
          if (!clazz && this.customClass.valueClasses && this.valuedEnumGroup) {
            const valuedEnum = LocalDbDao.getValuedEnumItemByValue(this.valuedEnumGroup, value)
            if (valuedEnum) clazz = this.customClass.valueClasses[valuedEnum.key]
          }
          if (!clazz && this.customClass.simpleMather) {
            // 按value内容简单匹配
            if (this.customClass.simpleMather.hasContent && !_.isNil(value)) {
              clazz = this.customClass.simpleMather.hasContent
            }
            if (this.customClass.simpleMather.positive && _.isNumber(value) && value > 0) {
              clazz = this.customClass.simpleMather.positive
            }
          }
          if (!clazz && this.customClass.customMatcher) {
            // 自定义匹配
            clazz = this.customClass.customMatcher(param.node.data)
          }
          return clazz || ''
        }
      }

      // valuedEnum类型的额外处理
      if (this.typeKey === ExplorerScalarType.ValuedEnum) {
        if (!this.valuedEnumGroup) {
          throw new Error(`未指定ValuedEnum类型 ${this.name}(${this.valuedEnumGroup})`)
        }
        if (!this.customCellRenderer) {
          columnDef.valueFormatter = params => {
            const value = _.get(params.data, columnDef.field || '')
            if (_.isNil(value)) return ''
            if (_.isArray(value)) {
              return value.map(v => LocalDbDao.getValuedEnumItem(this.valuedEnumGroup || '', v))
                .filter(v => v).map(v => v.value).join(',')
            } else {
              const valuedEnum = LocalDbDao.getValuedEnumItem(this.valuedEnumGroup || '', value)
              return valuedEnum?.value
            }
          }
        }
      }

      // date类型的额外处理
      if (this.typeKey === ExplorerScalarType.Date ||
        this.typeKey === ExplorerScalarType.DateTime) {
        columnDef.valueGetter = params => {
          const value = _.get(params.data, columnDef.field)
          return value && moment(value).toDate()
        }
      }

      // 如果没有权限
      if (this.typeKey === ExplorerScalarType.Int ||
        this.typeKey === ExplorerScalarType.Float) {
        columnDef.valueGetter = params => {
          const value = _.get(params.data, columnDef.field || '')
          if (value === NO_PERMISSION_NUMBER) return '无权限查看'
          return value
        }
      }

      // tags额外参数
      if (this.key === 'tags' && this.isList) {
        columnDef.cellRendererParams = {
          explorerTypeKey: this.parentTypeKey
        }
      }

      // master-detail设置
      if ((this.expandField || this.expandType) && !options.expendingParentField) {
        columnDef.cellRenderer = 'agGroupCellRenderer'
      }
      return [columnDef]
    } else if (!_.isEmpty(this.nestedFields)) {
      // nested fields, recurse
      if (options.groupHeader) {
        // group nestedFields
        return [{
          headerName: this.name,
          children: _.flatten(this.explorerType.fields()
            .filter(field => _.indexOf(this.nestedFields, field.key) >= 0 &&
              !field.ignoreColumn && // 不需要展示的字段
              (!options.expendingParentField || !this.expandIgnoreFields || _.indexOf(this.expandIgnoreFields, field.key) < 0)) // 展开时跳过获取的字段)
            .map(field => {
              const fieldPath = options.fieldPath || ''
              const fullKeyPath = fieldPath + (fieldPath ? '.' : '') + this.key
              return field.buildAgGridColumnDef({
                fieldPath: fullKeyPath,
                groupHeader: true,
                // 级联下去的自定义查询重定向, 全部都指向最开始指向的那个字段
                customRemapField: options.customRemapField || this.customRemapField,
                customRemapColumnType: options.customRemapColumnType || this.customRemapColumnType,
                expendingParentField: options.expendingParentField
              }, context)
            }))
        }]
      } else {
        // flatten nested fields
        return _.flatten(this.explorerType.fields()
          .filter(field => _.indexOf(this.nestedFields, field.key) >= 0 &&
            !field.ignoreColumn && // 不需要展示的字段
            (!options.expendingParentField || !this.expandIgnoreFields || _.indexOf(this.expandIgnoreFields, field.key) < 0)) // 展开时跳过获取的字段)
          .map(field => {
            const fieldPath = options.fieldPath || ''
            const fullKeyPath = fieldPath + (fieldPath ? '.' : '') + this.key
            return field.buildAgGridColumnDef({
              fieldPath: fullKeyPath,
              fieldNamePrefix: this.concatFieldName ? (options.fieldNamePrefix || '') + this.name : '',
              // 级联下去的自定义查询重定向, 全部都指向最开始指向的那个字段
              customRemapField: options.customRemapField || this.customRemapField,
              customRemapColumnType: options.customRemapColumnType || this.customRemapColumnType,
              expendingParentField: options.expendingParentField || field
            }, context)
          }))
      }
    } else if (this.customCellRenderer) {
      const fieldPath = options.fieldPath || ''
      const columnDef: ColDef = {
        headerName: (options.fieldNamePrefix || '') + this.name,
        field: fieldPath + (fieldPath ? '.' : '') + this.key,
        cellRenderer: this.customCellRenderer,
        cellRendererParams: Object.assign({
          explorerTypeKey: this.parentTypeKey,
          enumGroup: this.valuedEnumGroup
        }, this.customCellRendererProps),
        autoHeight: true,
        hide: this.hiddenByDefault
      }
      columnDef.filterParams = {
        viewDef: this.agGridFilterViewDef(columnDef)
      }
      this.processRemapFilter(columnDef, options, context)
      if (this.customFloatingFilter) columnDef.floatingFilterComponent = this.customFloatingFilter
      columnDef.sortable = false
      columnDef.enableRowGroup = false
      return [columnDef]
    } else {
      throw new Error(`不支持的字段类型 ${this.name}(${this.typeKey})`)
    }
  }
}

function resolveFilterViewType(columnType: string) {
  switch (columnType) {
    case 'stringColumn':
      return FilterViewType.StringFilter
    case 'booleanColumn':
      return FilterViewType.BooleanFilter
    case 'numberColumn':
      return FilterViewType.NumberFilter
    case 'dateColumn':
      return FilterViewType.DateFilter
    case 'valuedEnumColumn':
      return FilterViewType.EnumFilter
    case 'tagsColumn':
      return FilterViewType.TagsFilter
    default:
      return FilterViewType.ReadonlyFilter
  }
}
