
























































export default function (Component) {
  Component.options.__source = "src/module/plugin-attendance/views/filter/cmp-atd-employee-shift-floating-filter.vue"
}
